<template>
  <div class="Courses-list-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
          ref="dropdownFormFilter"
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-400">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Tên nhóm"
                    placeholder="Nhập tên nhóm"
                    name="Name"
                    :value.sync="filter.Name"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Coach"
                    placeholder="--- Chọn coach ---"
                    name="coach"
                    :solid="false"
                    :allowEmpty="true"
                    :value.sync="filter.coachId"
                    :options="coachesList"
                    value-label="fullNameSearch"
                    track-by="id"
                    @update:value="getCoachName"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Status"
                    placeholder="--- Chọn status ---"
                    name="status"
                    :options="statuses"
                    :allowEmpty="true"
                    :value.sync="filter.status"
                    track-by="value"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.prevent="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button class="btn btn-success ml-2" type="button" @click="showModal">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Tạo nhóm Zalo
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          type="button"
          @click="createItem"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm mới
        </b-button>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <b-container fluid class="Courses-list-page__body mb-6 mt-6">
        <b-row>
          <b-col>
            <div class="card card-custom gutter-b">
              <div class="card-body mt-0">
                <template-table
                  :column="column"
                  :data="this.data"
                  :paging="paging"
                  :tableAction="false"
                  :selectAction="false"
                  :searchAction="false"
                  boundary="window"
                  @sortBy="sortRequest"
                >
                  <template v-slot:body="{ item }">
                    <td>
                      <div class="grap-info">
                        <avatar
                          size="40px"
                          :src="getAvatar(item.logo)"
                          :rounded="true"
                        />

                        <div class="d-flex flex-column ml-3">
                          <p class="mb-0 module-list-page__body__updater-name">
                            {{ item.groupName }}
                          </p>
                          <p
                            class="mt-2 mb-0 module-list-page__body__updater-code"
                          >
                            <strong>{{ item.name }}</strong>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style="width: 30px">
                      <action-dropdown
                        :value="item"
                        :show_edit="true"
                        :show_delete="
                          !showActiveBtn(item) &&
                          (isWriteAllPermission || isWriteOwnerPermission)
                        "
                        :show_copy="false"
                        :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                        :show_view="true"
                        @edit="editItem"
                        @view="viewItem"
                        @delete="showPopupChangeStatus(item.id, 1)"
                      >
                        <template v-if="showActiveBtn(item)">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                          >
                            <a
                              class="navi-link text-primary"
                              @click.stop="showPopupChangeStatus(item.id, 0)"
                            >
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/power.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">Active</span>
                            </a>
                          </b-dropdown-text>
                        </template>
                      </action-dropdown>
                    </td>
                    <td>
                      {{ getCoachName(item.coachId) }}
                    </td>
                    <td>
                      {{ item.numberMemberJoin }}
                    </td>

                    <td>
                      {{ convertTimestampToDate(item.startDatetime, true) }}
                    </td>
                    <td>
                      {{ convertTimestampToDate(item.endDatetime, true) }}
                    </td>
                    <td class="statuscourse">
                      <div
                        class="statuscourse"
                        :class="
                          getClass(
                            item.startDatetime,
                            item.endDatetime,
                            item.duration,
                          )
                        "
                      >
                        {{
                          getClass(
                            item.startDatetime,
                            item.endDatetime,
                            item.duration,
                          )
                        }}
                      </div>
                    </td>
                    <td class="status">
                      <div class="status" :class="getStatus(item.status)">
                        {{ getStatus(item.status) }}
                      </div>
                    </td>
                  </template>
                </template-table>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
    <ZaloModal ref="zalo-modal" />
  </div>
</template>

<script>
import moment from 'moment';
import ZaloModal from './components/ZaloModal.vue';

export default {
  components: {
    ZaloModal,
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        Name: null,
        Description: null,
        Logo: null,
        Status: null,
        MaxMember: null,
        NumberMemberJoin: null,
        NumberMemberFinish: null,
        coachId: null,
        orderBy: null,
      },
      sort: {
        by: null,
        order: null,
      },
      statuses: [
        {
          name: 'Active',
          value: 0,
        },
        {
          name: 'Inactive',
          value: 1,
        },
      ],

      column: [
        {
          key: 'name',
          label: 'Tên khóa',
          sortable: true,
         
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '15px',
          },
        },
        {
          key: 'coachId',
          label: 'Coach',
          sortable: false,
        
        },
        {
          key: 'numberMemberJoin',
          label: 'Số lượng tham gia',
          sortable: false,
         
        },
        // {
        //   key: 'numberMemberFinish',
        //   label: 'Số lượng đã kết thúc',
        //   sortable: false,
        // },
        {
          key: 'createDatetime',
          label: 'Thời gian bắt đầu',
          sortable: true,
        },
        {
          key: 'endDatetime',
          label: 'Thời gian kết thúc',
          sortable: false,
        },
        {
          key: 'currentWeek',
          label: 'Tuần hiện tại',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
        },
      ],

      data: [],
      loading: false,
      selectedItems: [],
      coachesList: [],
      coursesData: [],
    };
  },
  computed: {
    searchParams() {
      return {
        name: this.filter.Name || null,
        code: this.filter.code || null,
        coachId: this.filter.coachId,
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        accountId: this.filter.healthCoach?.value || null,
        status: this.filter.status,
      };
    },
  },
  watch: {
    'paging.page': function () {
      this.getCourses();
    },
    'paging.pageSize': function () {
      this.getCourses();
    },
  },
  async created() {
    await this.getCourses();
  },
  methods: {
    showModal() {
      this.$refs['zalo-modal'].show();
    },
    getClass(startDatetime, endDatetime, duration) {
      const weeks = this.weeksBetweenNowAnd(endDatetime);
      console.log(weeks);
      const currentDate = new Date();

      const endDate = endDatetime;
      const startDate = startDatetime;
      if (startDate > Math.floor(currentDate.getTime() / 1000)) {
        return 'Waiting';
      } else if (endDate < Math.floor(currentDate.getTime() / 1000)) {
        return 'Done';
      } else if (weeks <= duration) {
        return Number(duration) - Number(weeks) + 1 + '/' + duration;
      } else {
        return '-';
      }
    },

    convertTimestampToDate(timestamp, inSeconds = false) {
      // Convert timestamp to milliseconds if it's in seconds
      if (inSeconds) {
        timestamp *= 1000;
      }

      // Create a new Date object using the timestamp
      let date = new Date(timestamp);

      // Extract day, month, and year
      let day = date.getDate().toString().padStart(2, '0');
      let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      let year = date.getFullYear();

      // Format the date as dd/mm/yyyy
      let formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    },

    weeksBetweenNowAnd(enddate) {
      // Parse the end date from the provided string
      const endDate = enddate;

      // Get the current date and time
      const currentDate = new Date();

      // Calculate the difference in milliseconds
      const diffInMilliseconds =
        endDate - Math.floor(currentDate.getTime() / 1000);

      // Convert milliseconds to weeks

      const diffInWeeks =
        (diffInMilliseconds * 1000) / (7 * 24 * 60 * 60 * 1000);

      // Round up to the nearest whole number
      const roundedWeeks = Math.ceil(diffInWeeks);

      return roundedWeeks;
    },

    getAvatar(imageId) {
      if (imageId) {
        return process.env.VUE_APP_API + `/Image/${imageId}`;
      }
    },
    async changeStatus(id, status) {
      this.$store.commit('context/setLoading', true);
      const formData = new FormData();

      formData.append('id', id);
      formData.append('status', status);
      const { error } = await this.$api.put(
        '/Courses/Status/' + `${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.getCourses();

      this.$store.commit('context/setLoading', false);
    },
    showActiveBtn(item) {
      if (item.status === 0) {
        return false;
      }
      return true;
    },
    showPopupChangeStatus(id, status) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          status ? 'Active' : 'Inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(id, status);
          }
        }.bind(this),
      );
    },
    format_date(value) {
      if (value) {
        return moment.unix(Number(value)).format('DD/MM/YYYY');
      }
    },

    searchRequest() {
      this.resetPaging();
      document.body.click();
    },
    resetRequest() {
      this.filter.Name = null;
      this.filter.status = null;
      this.filter.coachId = null;

      this.getCourses();
      // document.body.click();
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.getCourses();
    },
    viewItem(item) {
      this.$router.push({
        name: 'courses_view',
        params: {
          id: item.id,
          coachesList: this.coachesList,
        },
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'courses_edit',
        params: {
          id: item.id,
          coachesList: this.coachesList,
          coursesData: this.coursesData
        },
      });
    },
    createItem() {
      this.$router.push({
        name: 'courses_create',
        params: { coachesList: this.coachesList, coursesData: this.coursesData},
      });
    },
    // searchDebounce(value) {
    //   this.filter.name = value;
    //   this.resetPaging();
    // },
    resetPaging() {
      this.paging.page = 1;
      this.getCourses();
    },
    getCoachName(coachId) {
      const coach = this.coachesList.find((coach) => coach.id === coachId);
      return coach.fullNameSearch;
    },

    async getCoachList() {
      const coachList = await this.$api.get('/Account/Coachs');
      this.coachesList = coachList.data;
    },
    async getCourses() {
      this.$store.commit('context/setLoading', true);
      try {
        const params = this.searchParams;
        const { data, meta, error } = await this.$api.get('/Courses', {
          params,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.paging.total = data.total;
        this.data = data.items;
        this.coursesData=this.data.filter(x=>x.status===0);
       console.log("this.coursesData",this.coursesData)
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
        });
      } finally {
        await this.getCoachList();
        this.$store.commit('context/setLoading', false);
      }
    },
    getPositionClass(value) {
      if (typeof value === 'number' && !isNaN(value)) {
        return 'text-right';
      } else if (typeof value === 'string') {
        return 'text-left';
      } else if (
        (Object.prototype.toString.call(value) === '[object Date]' &&
          !isNaN(value)) ||
        !isNaN(Date.parse(value))
      ) {
        return 'text-center';
      } else {
        return 'text-right';
      }
    },

    returnPage() {
      this.$router.go(-1);
    },
    copyItem(item) {
      this.$router.push({
        name: 'courses_detail',
        params: {
          form_type: 'copy',
          id: item.id,
        },
      });
    },

    getStatus(status) {
      if (status) return 'inactive';
      return 'active';
    },
  },

  calculateWeeksBetweenTimestamps(startTimestamp, endTimestamp) {
    // Convert Unix timestamps to Date objects
    const startDate = new Date(startTimestamp * 1000);
    const endDate = new Date(endTimestamp * 1000);

    // Calculate the difference in milliseconds
    const diffTime = endDate - startDate;

    // Convert milliseconds to days
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    // Calculate the number of weeks
    const weeks = Math.floor(diffDays / 7);

    // Check if there are remaining days
    const remainingDays = diffDays % 7;

    // If there are remaining days, add one more week
    return weeks + (remainingDays > 0 ? 1 : 0);
  },
};
</script>
<style lang="scss" scoped>
.status {
  padding: 6px 24px;
  border-radius: 10px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }
}

.statuscourse {
  padding: 6px 24px;
  border-radius: 10px;
  color: black;
  text-align: left;
  width: 96px;

  &.Waiting {
    color: #fff;
    background-color: orange; /* Color for courses that haven't started */
  }

  &.Done {
    color: #fff;
    background-color: rgb(33, 165, 103); /* Color for ongoing courses */
  }

  &.Inprocess {
    background-color: #21a567;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 850px;
    }
  }
}

.search-input {
  min-width: 220px;
}
.grap-info {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 20px;
  align-items: center;
  width: 100%;
}
</style>
