<template>
  <div>
    <b-modal
      id="zalo-modal"
      ref="zalo-modal"
      title="Tạo nhóm Zalo"
      size="l"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      @cancel="resetModal"
      @hide="resetModal"
    >
      <b-overlay>
        <b-container class="p-0">
          <form ref="form">
            <b-row>
              <b-col>
                <label for="group-name">
                  Tên nhóm <span style="color: red">*</span>
                </label>
                <b-form-input
                  id="group-name"
                  v-model="form.group_name"
                  placeholder="Nhập tên nhóm"
                  required
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <basic-select
                  label="Loại nhóm"
                  placeholder="--- Chọn loại nhóm ---"
                  track-by="value"
                  value-label="text"
                  :options="zaloTypes"
                  :value.sync="form.group_description"
                  @change="clearDates"
                  required
                  name="this.data"
                  v-validate="'required'"
                  changeValueByObject
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  v-b-tooltip.hover="
                    'Bạn phải cần phải có quyền Admin OA để khi tạo nhóm có thể tham gia ngay vào group'
                  "
                >
                  <basic-select
                    label="Chỉ định Health Coach"
                    placeholder="Chỉ định health coach cho khóa"
                    track-by="id"
                    value-label="fullNameSearch"
                    name="coach"
                    v-model="coach_id"
                    :options="coachesList"
                    :multiple="true"
                    required
                  />
                </div>
              </b-col>
            </b-row>
            <b-col>
              <div>
                <!-- Button to toggle the collapse -->
                <b-button v-b-toggle.collapse1>
                  <img
                    src="/media/svg/icons/Code/Settings4.svg"
                    alt="Settings Icon"
                    class="icon"
                  />
                  <span class="ml-2">Cài đặt nâng cao</span>
                </b-button>
              </div>
            </b-col>
            <!-- Collapsible Content -->

            <b-collapse id="collapse1">
              <!-- First Section: Lock Send Message -->
              <!-- <b-col>
                <b-row>
                  <b-col>
                    <div class="bold-label">
                      Cho phép thành viên trong nhóm nhắn tin
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-radio
                      :labelCols="3"
                      inline
                      name="lock_send_msg"
                      v-model="form.lock_send_msg"
                      :options="radioOption1"
                    ></basic-radio>
                  </b-col>
                </b-row>
              </b-col> -->

              <!-- Second Section: Approval Mode for New Members -->
              <b-col>
                <b-row>
                  <b-col>
                    <div class="bold-label">
                      Cài đặt chế độ phê duyệt thành viên mới
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-radio
                      :labelCols="3"
                      inline
                      name="join_appr"
                      v-model="form.join_appr"
                      :options="radioOption2"
                    ></basic-radio>
                  </b-col>
                </b-row>
              </b-col>

              <!-- Third Section: Enable Message History -->
              <b-col>
                <b-row>
                  <b-col>
                    <div class="bold-label">
                      Cho phép thành viên mới đọc tin nhắn gần nhất
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-radio
                      :labelCols="3"
                      inline
                      name="enable_msg_history"
                      v-model="form.enable_msg_history"
                      :options="radioOption3"
                    ></basic-radio>
                  </b-col>
                </b-row>
              </b-col>

              <!-- Fourth Section: Enable Link Join -->
              <!-- <b-col>
                <b-row>
                  <b-col>
                    <div class="bold-label">
                      Cho phép tham gia nhóm bằng link
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-radio
                      :labelCols="3"
                      inline
                      name="enable_link_join"
                      v-model="form.enable_link_join"
                      :options="radioOption4"
                    ></basic-radio>
                  </b-col>
                </b-row>
              </b-col> -->
            </b-collapse>
            <br />
            <b-row>
              <b-col>
                <div>
                  Hạn mức nhóm còn lại:
                  <strong>{{ this.filterStatus.length }}</strong>
                </div>
              </b-col>
              <b-col>
                <div>
                  Tổng nhóm đã tạo:
                  <strong>{{ this.filterStatusAvailabel.length }}</strong>
                </div>
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <b-button
            class="btn btn-plain ml-2"
            href="#"
            @click.stop="handleCancel"
            tabindex="0"
          >
            Thoát
          </b-button>
          <b-button
            class="btn btn-success ml-2"
            href="#"
            @click.stop="handleUpdate"
            tabindex="0"
          >
            <span class="svg-icon">
              <inline-svg
                src="/media/svg/icons/Neolex/Arrows/refresh-ccw.svg"
              />
            </span>
            Cập nhật hạn mức
          </b-button>

          <b-button
            class="btn btn-success ml-2"
            href="#"
            @click.stop="handleSubmit"
            tabindex="0"
            :disabled="isSubmitting"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Tạo nhóm
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Success Modal -->
    <b-modal
      id="success-modal"
      ref="success-modal"
      title="Thông tin của nhóm"
      size="sd"
      @ok="handleSuccessOk"
      hide-footer
    >
      <b-container>
        <row>
          <form>
            <b-col>
              <div class="group-name">
                <strong>Tên nhóm: </strong> {{ data.name }}
              </div>
            </b-col>
            <b-col>
              <div>
                <strong>Link nhóm: </strong>
                <a :href="data.group_link" target="_blank">{{
                  data.group_link
                }}</a>
                <b-button
                  class="btn btn-success ml-2"
                  @click="copyToClipboard()"
                >
                  Copy
                </b-button>
              </div>
            </b-col>

            <b-col>
              <div>
                <strong>Loại nhóm:</strong>
                {{ this.changeText(data.group_description) }}
              </div>
            </b-col>
          </form>
        </row>
      </b-container>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          @click.stop="handleCancelSuccess"
          tabindex="0"
        >
          Quay lại
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ZaloModal',
  data() {
    return {
      data: '',
      selectedPackage: '',
      form: {
        group_name: '',
        group_description: '',
        asset_id: '',
        member_user_ids: [],
        lock_send_msg: false,
        join_appr: false,
        enable_msg_history: false,
        enable_link_join: true,
        group_avatar: '',
      },

      zaloTypes: [
        { value: 'common', text: 'Nhóm Chung' },
        { value: 'private', text: 'Nhóm HealthCoach' },
        // Add more courses as needed
      ],
      radioOption1: [
        { name: 'Không cho phép', value: true },
        { name: 'Cho phép', value: false },
      ],
      radioOption2: [
        { name: 'Không cần duyệt', value: false },
        { name: 'Phải duyệt', value: true },
      ],
      radioOption3: [
        { name: 'Không cho phép xem', value: false },
        { name: 'Cho phép xem', value: true },
      ],
      radioOption4: [
        { name: 'Không cho phép', value: false },
        { name: 'Cho phép', value: true },
      ],
      filterStatusAvailabel: [],
      filterStatus: [],
      coachesList: [],
      coach_id: '',
      member_user_ids: new Set(),
      member_user_name: new Set(),
      isSubmitting:false

    };
  },
  watch: {
    form: {
      handler(newVal) {
        if (newVal) {
          //   console.log(this.form.join_appr);
          // this.generateName();
        }
      },
      deep: true,
    },
    coach_id: {
      handler() {
        this.updateMemberUserIds();

        // console.log('member_user_ids', [...this.member_user_ids]);
        // console.log('member_user_NAME', [...this.member_user_name]);

        // console.log('description', this.form.group_description.value);
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    await this.getCoachList();
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    updateMemberUserIds() {
      this.member_user_ids.clear();
      this.member_user_name.clear();


      if (this.coach_id && Array.isArray(this.coach_id)) {
        this.coach_id.forEach((coachId) => {
          const results = this.coachesList.filter(
            (filter) => filter.id == coachId,
          );

          results.forEach((result) => {
            if (result && result.zaloUserId) {
              this.member_user_ids.add(result.zaloUserId);
              this.member_user_name.add(result.fullNameSearch);
            }
          });
        });
      }
    },

    async getCoachList() {
      const coachList = await this.$api.get('/Account/ZaloIdCoachs');
      this.coachesList = coachList.data;
    },
    changeText(text) {
      if (text === 'common') {
        return 'Nhóm Chung';
      } else if (text === 'private') {
        return 'Nhóm HealthCoach';
      }
    },

    copyToClipboard() {
      // Call the navigator clipboard API to copy text
      navigator.clipboard
        .writeText(this.data.group_link)
        .then(() => {
          this.$toastr.s({
            title: 'Thành công',
            msg: 'Đã sao chép link nhóm',
          });
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    },

    async loadData() {
      try {
        const body = {
          quota_owner: 'OA',
          quota_type: 'sub_quota',
        };

        const response = await this.$api.post('/ZaloZnsLog/GetAssetOA', body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        this.filterStatusAvailabel = response.data.filter(
          (item) => item.status === 'used',
        );
        this.filterStatus = response.data.filter(
          (item) => item.status === 'available',
        );
      } catch (error) {
        console.log(error);
      }
    },

    show() {
      this.$refs['zalo-modal'].show();
    },
    hide() {
      this.$refs['zalo-modal'].hide();
    },
    async handleUpdate() {
      await this.loadData();
      this.$toastr.s({
        title: 'Thành công!',
        msg: 'Đã cập nhật hạn mức tạo nhóm',
      });
    },

    async handleSubmit() {
      const groupName = this.form.group_name;
      const groupDescription = this.form.group_description.value;
      const groupDescriptionName = this.form.group_description.text;

      this.isSubmitting=true;

      if (this.filterStatus.length === 0) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Đã đạt hạn mức tạo nhóm. Vui lòng cập nhật lại hạn mức !!!',
        });
        this.isSubmitting=false;
        return;
      }

      if (!groupName || !groupDescription) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Tên nhóm và loại nhóm không được để trống.',
        });
        this.isSubmitting=false;
        return;
      }

      if (groupName.trim().length >= 200) {
        // Check if group description is less than 10 characters
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Vui lòng chọn tên dưới 200 ký tự',
        });
        this.isSubmitting=false;
        return;
      }

      if (typeof groupName !== 'string' || groupName.trim().length < 10) {
        // Check if group description is less than 10 characters
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Mô tả nhóm là text và có ít nhất 10 ký tự.',
        });
        this.isSubmitting=false;
        return;
      }

      try {
        // Construct the JSON body
        const body = {
          group_name: groupName,
          group_description: groupDescription,
          asset_id: 'd20c9bd4888961d73898',
          member_user_ids: [...this.member_user_ids],
        };

        // Make the API call with JSON body
        const response = await this.$api.post(
          '/ZaloZnsLog/CreateGroupOA',
          body,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        this.data = response.data;
        // Check if there is an error in the response
        if (response.data.error) {
          this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Zalo Id không hợp lệ.',
        });
        this.isSubmitting=false;
        }

        // Display success message
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Nhóm zalo được tạo thành công.',
        });

        // Show success modal
        this.resetModal();
        this.$refs['success-modal'].show();
      } catch (error) {
        // Display error message
        this.$toastr.e({
          title: 'Lỗi !',
          msg: "Zalo Id không hợp lệ! Vui lòng liên hệ với bộ phận kỹ thuật để được hỗ trợ. ",
        });
        this.isSubmitting=false;

      } finally {
        await this.loadData();
        await this.assginZaloAdmin(this.data.group_id,groupDescriptionName);
        console.log("group_id",this.data.group_id);

      }
    },

    async assginZaloAdmin(group_id,group_description) {
      this.form.lock_send_msg =
        this.form.group_description.value === 'common' ? true : false;
        console.log("this.form.group_description",this.form.group_description.value)
      this.form.group_avatar =
        'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_e575ba7d5712488190aef827d0bcface/Image%20Website/logo-diab-offical';
      const zaloChooseCoach = "Type Group: "+group_description+ "\nHealth Coach: "+[...this.member_user_name] +"\nIDs: "+[...this.member_user_ids] ;
      const body = {
        group_id: group_id,
        group_avatar: this.form.group_avatar,
        lock_send_msg: this.form.lock_send_msg,
        join_appr: this.form.join_appr,
        enable_msg_history: this.form.enable_msg_history,
        enable_link_join: this.form.enable_link_join,
        group_description: zaloChooseCoach,
      };
      return this.$api.post('/ZaloZnsLog/UpdateZaloGroupInfo', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },

    handleCancelSuccess() {
      this.$refs['success-modal'].hide();
      this.resetModal();
    },
    handleCancel() {
      this.hide();
      this.resetModal();
    },
    handleSuccessOk() {
      this.hide();
      this.resetModal();
    },
    resetModal() {
      // Reset modal state if needed
      this.form.group_name = '';
      this.form.group_description = '';
      this.isSubmitting=false;

    },
  },
};
</script>
<style scoped>
.no-footer .modal-footer {
  display: none;
}
.group-name {
  display: inline-block; /* Ensures the ellipsis works correctly */
  max-width: 500px; /* Adjust this width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle; /* Aligns the text properly with the label */
}

.bold-label {
  font-weight: bold;
  color: #333; /* Darker text color */
  text-align: left; /* Ensure text is aligned to the left */
}

button.btn.btn-secondary.collapsed {
  margin: -15px;
}
button.btn.btn-secondary.not-collapsed {
  margin: -15px;
}
</style>
